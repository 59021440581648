import React from 'react'
import loadable from '@loadable/component'
import 'uikit/dist/css/uikit.css'
import Paragraph from '../components/paragraph/paragraph';
import { information } from "../utils/newsContentInformation"

const Layout = loadable(() => import( '../layouts'))


const NewsPage = ({pageContext, props}) => {
   
    const {origin} = pageContext

    return (
        <Layout SEOTitle={"News | Free Motor Legal"} SEODescription={""} footerDisclaimer={""}>

            <Paragraph props={information} origin={origin} />
             
        </Layout>
    )
}

export default NewsPage

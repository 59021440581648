import nav from '../assets/nav.png'
import fluids from '../assets/fluids.png'
import tyres from '../assets/tyres.png'
import games from '../assets/games.png'
import kit from '../assets/kit.png'
import packed from '../assets/packed.png'
import picnic from '../assets/picnic.png'
import route from '../assets/route.png'
import remedies from '../assets/remedies.png'
import arrow from '../assets/arrow-going-up.png'
import cars from '../assets/cars.png'
import score from '../assets/score.png'
import internet from '../assets/internet-music.png'
import wires from '../assets/wires.png'
import gatso from '../assets/gatso.png'
import van from '../assets/camera-van.png'
import hadecs from '../assets/hadecs.png'
import specs from '../assets/specs.png'
import truvelo from '../assets/truvelo.png'

export const information = {
    "motor-2": [
        {
            title: 'What is motor legal protection',
            subtitle: 'by fml | Aug 10, 2018 | Uncategorized',
            alignment: 'left',
            inline: true,
            description: [
                {
                    text: "Take a look at our quick informative video which explains what motor legal protection does and how you can get the benefits of motor legal protection free for life by becoming a member of Free Motor Legal"
                }
            ],
            youtubeUrl: 'https://www.youtube.com/embed/lF4ZAwqIjnM'
        }
    ],
    "holiday": [
        {
            title: 'Are you ready for those summer holiday car journeys? Our top 10 tips',
            subtitle: 'by fml | Jul 6, 2018 | Uncategorized',
            alignment: 'left',
            inline: false,
            description: [
                {
                    titleDes: '1. Plan Your Journey',
                    image: nav,
                    text: 'Avoid stress whilst you’re on the road and plan ahead. It is well worth planning a route to take across the country, planning roughly how much you can spend each day and planning activities that you can stop and do along the way.'
                },
                {
                    titleDes: '2. Check Your Car’s Fluids',
                    image: fluids,
                    text: 'To keep your car in tip top condition and avoid the risks of breakdowns, you should make sure the following fluids are kept topped up: including engine oil, windscreen washer fluid, engine coolant and of course, fuel.'
                },
                {
                    titleDes: '3. Check Your Tyres',
                    image: tyres,
                    text: 'Take a few moments to check the tread and pressure of your car tyres. This may save you from breaking down or worse, a crash. Check that you have a road worthy spare wheel in your car in case of an emergency and maybe a can of tyre foam?'
                },
                {
                    titleDes: '4. Prepare Car Games',
                    image: games,
                    text: 'Preparing a range of car games that the children can play may delay or at best, prevent the dreaded question asked by every child on a car journey, ‘Are we there yet?’. Grrrrrrrr!'
                },
                {
                    titleDes: '5. An Emergency Kit',
                    image: kit,
                    text: 'No matter how old or new your car is, it could break down. An emergency kit should consist of a breakdown triangle, a blanket, a torch, high visibility jackets (legal requirement in France), a mobile phone, a map, and most importantly breakdown cover details. If you break down put on the hazard warning lights, get out of the car on the passenger side and move away from the vehicle.'
                },
                {
                    titleDes: '6. Pack The Car Well',
                    image: packed,
                    text: 'If you pack the car in a rush you have a tendency to miss things, and to forget where everything is in the car. Take your time, put things in the glove box that you will need on the journey. Make sure you can easily reach things like CD’s, tissues, snacks, map etc. Everything else should be packed in the order you will need them.'
                },
                {
                    titleDes: '7. Stop For A Picnic',
                    image: picnic,
                    text: 'Who says that your holiday has to start when you reach your destination? Why not start it on your journey and stop for a picnic along the way? Take a few snacks, sandwiches, crisps, drinks and fruit and find a nice spot to stop and have a picnic.'
                },
                {
                    titleDes: '8. Plan A Scenic Route?',
                    image: route,
                    text: 'Rather than just traveling along the boring motorways, why not plan a scenic route for your journey? Advantages of taking a scenic route to your destination include, avoiding long holiday tailbacks, stopping to stretch your legs more often, and discovering quaint little villages and beautiful scenery along the way.'
                },
                {
                    titleDes: '9. Take Anti Sickness Remedies',
                    image: remedies,
                    text: 'There is nothing worse than having poorly children in the back of the car. As well as over the counter anti sickness tablets you can use acupressure bracelets which have a small plastic button on the inside which put gentle pressure on acupuncture points. One final remedy would be to try ginger snap biscuits, as ginger can be a natural remedy to nausea.'
                },
                {
                    titleDes: '10. ',
                    
                    text: ''
                },
                {
                    titleDes: 'Have you got your Free Motor Legal membership & accident survival checklist?',
                    text: 'If the worst happens and you have a bump that was not your fault, calling our 24/7 claims line on 0800 144 44 88 could have you back on the road without needing to claim from your own insurance policy and avoid paying your excess.'
                }
            ]

        }
    ],
    information: [
        {
            title: 'Motor legal protection – the conversation nobody is yet having ahead of the Civil Liability Bill',
            subtitle: 'by fml | Jul 3, 2018 | Uncategorized',
            alignment: 'left',
            inline: true,
            description: [
                {
                    text: 'The government shows no signs in halting the progression of the Civil Liability Bill, with very few amendments taking place as it progresses through the parliamentary channels. Plans remain to have the blocks in place for April 2019.'
                },
                {
                    text: 'This will give rise to the proposed tariff based system for whiplash type soft tissue injuries as well as the increase in the small claims limit to £5000.00 for general damages on road accident claims.'
                },
                {
                    text: 'Whether the public at large will end up applauding the outcome will remain to be seen. Those opposing the reforms remain dubious as to the level of savings (if any) which will trickle down to the consumer with the promise of reduced premiums arising from the reforms by the insurer lobby. Savings could be as low as £16 a year (30p/week).'
                },
                {
                    text: 'The application of a £5000 small claims limit and tariff based damages system will capture the vast bulk of personal injury claims which arise from road traffic collisions.'
                },
                {
                    text: 'The application of a £5000 small claims limit and tariff based damages system will capture the vast bulk of personal injury claims which arise from road traffic collisions.'
                },
                {
                    text: 'So far, nobody appears to have voiced opinion as to how the legal expenses insurance marketplace will operate in such an environment and Lee Jones, managing director of Free Motor Legal Ltd, gives a background of his experiences of how the motor legal protection marketplace currently works and raises  questions of how will it may operate under the proposed new regime.'
                },
                {
                    text: 'First of all, let us all be honest. Motor Legal Protection (MLP)/ Legal Expenses Insurance (LEI) has for some time been a product with shady underpinnings in how it was sold to consumers and then how the product is actually operated when a claim is notified.'
                },
                {
                    text: 'Years of abuse in how it was sold as a default product needing the consumer to opt out, almost akin to PPI, was corrected in 2015 by the FCA. Customers now needing to opt in rather than it appearing automatically. Take up remains high due to a “better safe than sorry” approach, rather than an understanding of what the product is for and what it does.'
                },
                {
                    text: 'An attempt by the FCA following a thematic review into the sale of Add-ons in 2015 wished to lift the veil, with a suggestion that claims ratios and payout rates be published to help demonstrate value to consumers was quickly and successfully stifled by the ABI, I wonder why?'
                },
                {
                    text: 'The underwriting cost of the product is incredibly low compared to the retail price. It is not unusual to see unit costs as low as £0.50p, yet retailed typically at around £30.00. This amounts to a mark up of 6000%. I have even seen tenders for major UK insurers whereby they want the underwriting costs for free (paid for by the credit hire companies and law firms appointed to their panel to do ULR and PI work).'
                },
                {
                    text: 'Why are the underwriting costs so low? Because, in my experience, the underwriters of the policies seldom have to entertain paying any legal costs! It is not unheard of for “hold harmless” agreements to be entered into on the side by law firms, promising they will never seek an indemnity under the policy. This leaves the panel firms assuming all responsibility for any adverse costs and allows the underwriters to sleep easy in the knowledge they will not be troubled for payments against the policy. So they remain happy to take 50p per policy for issuing some documentation and doing little else.'
                },
                {
                    text: 'It does not cause any real harm to the consumer, after all, they get referred to a law firm and don’t have to pay any costs, but what is actually going on behind the scenes is of dubious merit.'
                },
                {
                    text: 'It was this modus operandi which gave me the lightbulb moment for Free Motor Legal, seeing that frankly it made little difference to the lawyer if there was LEI cover present or not. They knew they were never going to be able to rock up to the LEI provider and seek payment for costs and disbursements on a case they lost. So I was able to create a free alternative to the underwritten product which performs the same functions for motor claims in England, Scotland & Wales.'
                },
                {
                    text: 'Motorists sign up for free membership and in the event they have a non-fault accident and have losses or damage to claim, they will be referred to a lawyer and will not have to pay any fees and there are no success fee deductions. Essentially a LEI model without any actual LEI in place. Clients also benefit from an uplift of 10% on general damages due to the CFA in place with the panel law firm. So they actually get a better general damages settlement than somebody represented under a Legal Expenses policy!'
                },
                {
                    text: 'As cases are progressed by way of a no win no fee arrangement, the client has protection against costs under the Qualified One-Way Costs Shifting (QOCS).'
                },
                {
                    text: 'The system of LEI polices being used as a claims capture mechanism has operated for years and has seen colossal income streams for insurers and brokers alike. They have earned healthy mark ups from the sale of the product and thereafter impressive levels of income from referral fees and later “marketing fees” for personal injury and credit hire claims captured. Some insurers and brokers have danced around the regulations introduced in 2012 when referral fees were banned by simply taking ownership or financial interests in law firms under “Alternative Business Structures”.'
                },
                {
                    text: 'Now all of this is about to change, bringing about several questions.'
                },
                {
                    text: 'It is clear that LEI works by avoiding having to pay lawyers for their work. The “reasonable prospects of success” clause contained in every policy allows cases with dubious merit to be cast aside. This helps the lawyers to avoid taking on cases where they can see trouble or a significant risk of being left writing off fees and expenses. Again allowing the underwriters to dodge any possible costs liabilities down the line.'
                },
                {
                    text: 'Non-injury cases such as uninsured loss recovery have always been loss-making territory for the panel lawyer. These cases, sometimes requiring the instruction of counsel for a small claims hearing at a cost to the lawyer, were subsidised by the fee generating PI claims. Put simply, the lawyers get paid on the cases they win (by the third party insurers) and they absorb the losses on the cases they don’t win or are unable to progress. This again is the model Free Motor Legal adopted.'
                },
                {
                    text: 'So if we move to April 2019 and have 95% of personal injury claims falling within the small claims track, that will mean no costs pre-litigation like we currently see under the Low Value RTA Protocol  and meagre levels of any costs in litigated claims. So there will be no food on the table for lawyers in terms of fees.'
                },
                {
                    text: 'Due to the proposed tariff of damages, which will see somebody with a 3 month period of injuries receiving less than somebody delayed at Gatwick for 4 hours, there will be minimal levels of success fees to  deduct from damages.'
                },
                {
                    text: 'So a whole new territory will appear and this gives rise to several questions and concerns for consumers:'
                },
                {
                    text: 'After April 2019, will a person who has paid for a legal expenses policy with say, £100k of legal costs covered expect to see a panel law firm appointed to deal with their 6 month whiplash claim that will then be worth less than £500?'
                },
                {
                    text: 'Are LEI providers going to pay their panel lawyers to deal with such claims? I suspect not. If they do, I suspect the level of costs payable will be very small, causing such firms to restructure significantly.'
                },
                {
                    text: 'Are they going to take all services in-house? Or will exclusion clauses arise in rewritten policy T&C’s to again avoid the risk of outlays for legal costs arising? I predict matters being retained in-house and panel firms largely disappearing.'
                },
                {
                    text: 'Or will motorists, realising they are going to have to go it alone in the claims process, cease to buy Legal Expenses Insurance? Only time will tell, much depending on how educated the public are on such a matter. On past consideration, add-ons such as LEI are completely misunderstood and it is quite likely insurers may succeed in selling this product for many years to come, whilst having to provide less and less support.'
                },
                {
                    text: 'The absence of any proper income steams from personal injury work will also stifle the ability to cover the costs of the non-injury work, such as basic uninsured loss recovery litigation.'
                },
                {
                    text: 'Although the reforms carried under the Civil Liability Bill are not yet finalised and hopefully there will be some sensible debate within the House of Commons to realise that a lot of the objectives of the bill are already being met by the outcome of previous reforms whilst insurance company profits continue to soar, there has yet to be any soundbites from the insurance industry on how these products will work for consumers in the future and the legal services industry seems to have not yet offered any opinion yet.'
                },
                {
                    text: 'What are your views?'
                },
                {
                    text: 'Lee Jones is the managing director of Free Motor Legal Ltd.'
                },
            ]
        }
    ],
    "save": [
        {
            title: 'Top 10 tips to save on your motor insurance costs',
            subtitle: 'by fml | May 8, 2018 | Uncategorized',
            alignment: 'left',
            inline: false,
            description: [
                {
                    titleDes: 'Get the right level of cover – Fully comp or Third Party?',
                    text: 'It used to be that third party fire and theft cover was always cheaper, but in recent years insurers have been happy to provide lower premiums for fully comprehensive as customers with fully comp cover seem to be a better risk and less likely to claim. So get more for less by getting quotes for fully comprehensive cover as well as TPFT.'
                },
                {
                    titleDes: 'Don’t leave it until the last minute – you will end up paying more!',
                    text: 'Searching around for a new deal or switching car insurance 2-3 weeks before your due date results in average savings of £280.00 according to price comparison site comparethemarket.com The closer to the date you need cover to start before seeking out quotes results in the comparison sites and insurers dishing out more expensive quotes. Make sure you get your quotes in early. Many sites and insurers will guarantee the quote for 30 days.'
                },
                {
                    titleDes: 'Make sure you do compare and switch – never just let a policy auto renew.',
                    text: 'There is no reward for being a loyal customer, not with insurers anyway. As you are already experiencing, new customers get all the best deals. The insurers rely on snagging you as a new customer with an inviting deal and then hoping you are too lazy to shop around at renewal time, by which time the premium will have increased and you end up locked in for another year.'
                },
                {
                    titleDes: 'Add another experienced driver on to the policy as a named driver',
                    text: 'Adding a parent or other experienced driver onto your policy as a named driver can often lower premiums substantially, especially for younger drivers.'
                },
                {
                    titleDes: 'Pay up front – instalments = interest charged on top of your premium',
                    text: 'Although it is easy to say, considering motor insurance costs for some people can be a big ticket purchase, if you can afford to take the hit all at once, consider paying your full premium in one hit rather than monthly. When you opt to pay by instalments you often find that the instalments are financed by a separate financial agreement, where interest is added and this typically adds another £60 to the total cost of the average premium.'
                },
                {
                    titleDes: 'Do you need all the extras?',
                    text: 'Trimming back on some of the more common place “add-ons” like excess protection, guaranteed courtesy car cover can reduce premiums substantially. Also a cause close to our hearts is the added motor legal protection cover. This is typically £30 a year per vehicle. So a household with several cars could be buying this additional cover several times over. A free alternative can be found for motorists in England, Scotland & Wales., saving £30 per vehicle a year.'
                },
                {
                    titleDes: 'Set your excess higher to reduce the premium',
                    text: 'An excess is the amount you agree to contribute towards the cost of a claim you make. So by increasing your excess amount, you are going to cost your insurer less in the event of a claim, but don’t get carried away. If you set your excess very high, such as over £1000.00, you could find yourself not being able to afford to pay for repairs to your vehicle after a collision.'
                },
                {
                    titleDes: 'Cashback deals',
                    text: 'Price comparison sites and other introducers get commission payments from insurers when you take out cover through them. So instead try some of the sites like www.quidco.com and www.topcashback.co.uk to see what cashback deals you can get. Make you search for some quotes via the usual price comparison sites first so that you know what the typical premium before cashback is.'
                },
                {
                    titleDes: 'Job titles make a difference',
                    text: 'As strange as it sounds, the title or terminology you use can make a difference. You have to be honest though. So no point saying you are the chief executive of a multi-national company if you really work as a part time cleaner. But examples can be calling yourself “kitchen staff” when you work as a chef. The lovely people at moneysavinexpert.com have developed a tool to suggest job title changes which may lower your premium.'
                },
                {
                    titleDes: 'Haggle!',
                    text: 'Yep indeed, it costs nothing to ask for some discount and often there is some “wiggle room” to be had, resulting in a lower premium.'
                },
            ]
        }
    ],
    "insurance-companies": [
        {
            title: 'Insurance companies continue to play everyone for fools',
            subtitle: 'by fml | Aug 3, 2017 | Uncategorized',
            alignment: 'left',
            inline: true,
            image: arrow,
            description: [
                {
                    text: 'Another day and more reports from insurers of increased profits and reduced combined operating ratios, yet we are all paying more for our motor insurance than ever.'
                },
                {
                    text: 'Isn’t it about time that the real reasons behind premium increases were investigated along with the pricing models of insurers as well as the Competition an Markets Authority taking a look at the fact that, despite there being more insurance brands out there than ever, there are only approximately 10 actual underwriters in the UK marketplace? So how competitive is the marketplace really?'
                },
                {
                    text: 'We are amidst changing times where there are drastic reforms about to take place which are going to undo laws which are centuries old, all of this being sponsored by the UK Motor Insurance industry and their lobbying group the Association of British Insurers (ABI).'
                },
                {
                    text: 'The public have been duped by the ABI due to repeated reports and news items blaming premium increased on false whiplash claims and a pandemic of fraud, yet official figures show a reduction in claim volumes and a reduction in claims expenditure. So why are our premiums still increasing and why isn’t somebody taking the insurance industry to task about this when premiums are up, claim volumes and costs are down, yet profits are leaping up year on year?'
                },
                {
                    text: 'We at Free Motor Legal call for an enquiry into this as the UK insurance industry are taking their customers for fools and driving up the cost of motoring, whilst at the same time sponsoring wholesale reforms to the laws of the UK which will result in people who are genuinely injured and suffering with pain for over 6 months receiving less in compensation than somebody delayed at an airport for a few hours.'
                },
                {
                    text: 'The alleged savings people will enjoy when the whiplash reforms are introduced is around £35.00 a year. Well we can save motorists £30 every year by providing a free alternative to paying for motor legal protection. So why not register today for free?'
                },
            ]
        }
    ],
    "free": [
        {
            title: '5 things you now get for free that you always used to pay for',
            subtitle: 'by fml | Jul 17, 2017 | Uncategorized',
            alignment: 'left',
            inline: true,
            description: [
                {
                    text: 'Although it seems like prices on goods and services only ever go up (have you checked what is happening with car insurance? – (the greedy sods) if you look around, there are some things you can now get for free, largely due to what we call “disruptors” in the market place.'
                },
                {
                   boldTitle: 'These are the firms who see another way of doing things and the consumer goes on to benefit by the price either dropping or the product/ service becoming completely free as they “disrupt” the marketplace.  This is exactly what http://www.freemotorlegal.o.uk have done to the motor legal protection / legal expenses insurance marketplace, providing  motorists with a free alternative to paying for motor legal protection.',
                },
                {
                    image: cars,
                    boldTitle: '1 - Motor Legal Protection',
                    text: '– yes our favourite. For decades motorists have paid an extra £30 to their insurers or brokers for a 12 month motor legal protection/ legal expenses insurance policy. Since 2012 Free Motor Legal has been offering all motorists in England, Scotland & Wales a free alternative. Not only that but the free membership lasts for life, so no 12 month offer for new customers only. A full lifetime free membership, saving motorists millions every year. Join free today at www.freemotorlegal.co.uk'
                },
                {
                    image: score,
                    boldTitle: '2 - Your Credit Score',
                    text: '– Until recently you always had to pay to get your credit score or pay a subscription. Now firms like www.clearscore.com offer this for free and the big credit reference agencies like Experian have followed.'
                },
                {
                    image: internet,
                    boldTitle: '3 - Music on the internet',
                    text: '– In the early days of the internet, you could listen to music for free (albeit illegally) on Napster. Then, you had to pay for music through iTunes,  or similar services. Now we’re back to free music through apps such as Spotify and Pandora.'
                },
                {
                    image: nav,
                    boldTitle: '4 - Sat Nav / GPS',
                    text: '– Previously you had to go out and buy a separate sat nav unit to stick on the wondscreen of your car, usually spending upwards of £100 and then needing to update the maps etc.  If you have a smartphone (who doesn’t?), there’s really no reason to do so. There are plenty of free GPS apps that work perfectly fine. Google Maps for example.'
                },
                {
                    image: wires,
                    boldTitle: '5 - Long Distance phone calls',
                    text: '– If you don’t have a phone plan that already gives you free long-distance calls, check out services such as Skype and Google Voice, which allow you to make calls for free.'
                },
                {
                    boldTitle: 'Can you name some more?'
                }
            ]
        }
    ],
    "speed-cameras": [
        {
            title: 'Do you know your speed cameras?',
            subtitle: 'by fml | Jul 17, 2017 | Uncategorized',
            alignment: 'left',
            inline: true,
            description: [
                {
                    boldTitle: 'Can you tell the difference and know how they work?'
                },
                {
                    text: 'OK I have to profess a bit of personal interest in raising this post and bringing this subject to the attention of our members as I recently got nabbed for speeding by a mobile camera van. A fair cop, I was exceeding the speed limit and got caught. Although I would argue the van had been placed in an advantageous spot where it was not in view until it was too late and I was already in the sights of the salivating camera operator.'
                },
                {
                    boldTitle: 'So what are the most common forms of speed camera in use on the UK roads?'
                },
                {
                    image: gatso,
                    boldTitle: 'Gatso',
                    text: ' – These are the “old guard” and have been around since the 90’s. They mainly face the direction you are travelling in and therefore take a picture of the rear of your vehicle if your speed triggers the device. They operate by using radar and therefore can be located by radar detecting equipment. The original ones used actual film, so had to be attended to by police officers and have the film removed and processed. Newer models (2nd pic) are digital so are able to promptly send the evidence to the police for processing.'
                },
                {
                    image: van,
                    boldTitle: 'Mobile Speed Cameras',
                    text: ' – These are becoming an increasing sight on our roads as they are not fixed to one location and allows the operators to position themselves at various locations in the area they police, rather than just being a fixed camera always at the same location where people get to know and so slow down accordingly before accelerating again. Most police forces do publish the locations where the mobile “safety camera” locations are. The position the camera van (as seen above) parks is usually tactical and often although it is visible from a distance, the operator is usually aiming at traffic just as you come into view, thus maximising the number of people who are caught exceeding the speed limit before they have time to adjust their speed.'
                },
                {
                    image: hadecs,
                    boldTitle: 'HADECS 3 Cameras',
                    text: ' – These are seen as a bit more stealthy and are cropping up more and more as the favour for “smart motorways” increases with lots of overhead gantries and variable speed limits. With these cameras, they are often mounted at the side of gantries and are difficult to spot. They also adjust to any variation of speed limit, so if a temporary limit of say 50mph needs to be enforced, the camera settings change to reflect the temporary speed restriction in place. These too have no films present and any evidence gathered is sent electronically to a processing office.'
                },
                {
                    image: specs,
                    boldTitle: 'SPECS Cameras',
                    text: ' – These cameras are a familiar site on motorways and A roads and are often placed at locations where there are reduced speed limits, such as where road works are taking place. The difference with SPECS cameras to other conventional traffic enforcement cameras is that they measure your average speed. So rather than slowing down for the camera and then accelerating away again, you have to make certain you do not arrive at the next set of cameras too soon, otherwise the machine does the maths and works out your average speed was too high and a ticket is likely to show up in the post.'
                },
                {
                    image: truvelo,
                    boldTitle: 'Truvelo',
                    text: ' – are the second most popular fixed speed camera used on Britain’s road. The big difference between the Gatso and Truvelo safety camera, is the fact that Truvelo is a forward facing camera. Being forward facing means when triggered it takes a photo of the front of the vehicle including the driver, so unlike with the Gatso there is NO dispute who was driving. The more modern version Truvelo D Cam is shown in the 2nd pic above and these can either be mounted to take forwards or rearwards views, do not have any film and can also be used as red light cameras as well as monitoring up to 3 lanes of traffic at once.'
                },
                {
                    text: 'Whatever the method of detection used, speed cameras or “safety cameras” remain a common site on our roads and their deployment can often seem to be a cash generator to fleece the motorist for a few quid. However,. the bottom line is that you need to keep to the speed limits, be vigilant, allow plenty of time for your journey so you are not in a rush and be aware of the speed limit of the road you are driving on.'
                }

            ]
        }
    ],
    "cause": [
        {
            title: 'Are whiplash sufferers really the cause of higher insurance premiums?',
            subtitle: 'by fml | Nov 18, 2016 | Uncategorized',
            alignment: 'left',
            inline: true,
            description: [
                {
                    text: 'The Whiplash reforms proposed by the government on 17/11/16 are sponsored by the insurance industry and will struggle to pass on the alleged £40 savings per year on motor insurance costs.'
                },
                {
                    text: 'Free Motor Legal urges people to be cautious in their support of the reforms being tabled by the government as these are sponsored by the UK insurance industry, supported by their trade body The Association of British Insurers (ABI).'
                },
                {
                    text: 'The reforms on the face of it appear a welcome and popular way of bashing the premium increases down we have all been seeing over the last 18 months. But there are so many reasons motor premiums have increased and whiplash is far from being the route of all evil.'
                },
                {
                    text: 'Our previous blog revealed the REAL REASONS YOUR MOTOR PREMIUMS HAVE BEEN INCREASING.',
                },
                {
                    text: 'Nothing has changed since then and there remains concerns that the promises of savings will not be passed on. An independent financial ratings agency Fitch has already said that they doubt insurers will pass on the alleged savings.'
                },
                {
                    text: 'Already the promises of £50 savings from October 2016 on the ABI website have now dwindled by 20% a month later and we are now all promised £40 off our premiums.'
                },
            ]
        }
    ],
    "more-reasons": [
        {
            title: 'More reasons why your insurance premiums are going up',
            subtitle: 'by fml | Sep 22, 2016 | Uncategorized',
            alignment: 'left',
            inline: true,
            description: [
                {
                    text: 'All is not what it seems...'
                },
                {
                    boldTitle: '1 - Insurance Premium Tax (IPT)',
                    text: 'increased in November 2015 – Previously this tax was set at 6%, but George Osborne hiked it to 9.5% with effect from November 2015 – this saw premiums increase. But matters are not finished! A further hike to 10% is to coming into effect on 1st October 2016. So premiums can be expected to rise slightly more due to the actions of the treasury. The hike in IPT, which affects not only motor insurance, but also household insurance, pet insurance and legal expenses insurance, is expected to net the Treasury and additional £8.1 Billion by 2021.'
                },
                {
                    boldTitle: '2 - Solvency ii',
                    text: ' – a requirement from January 2016 for insurers to hold more capital reserves (money in the bank to meet claims and liabilities). So they can’t invest as much in stocks & shares or buy shopping malls etc. Believe it or not, motor insurers typically don’t make their core profits from underwriting profits (collecting more in premiums than they pay out in claims) although many have moved into this territory in recent years with Combined Operating Ratios (COR’s) as low as 85% (meaning for every £1 they collect in premiums, 85p is paid out in claims and operating overheads, leaving a 15% profit margin). Because they don’t have as much spare cash they can pump into investments, this has caused them to look elsewhere for increased profits – yep you guessed it – their customers; Crank up the premiums, crank up the profits! Funny how the increase in premiums has arisen at the same time insurers are able to report year on year improved profits.'
                },
                {
                    boldTitle: '3 - People wising up to the auto renewal fiasco',
                    text: '. The insurance industry really is a strange old bird. How many industries or businesses do you know that actually kick a loyal customer in the shins by charging them more than a new customer? Yes, it’s a strange one, but a fact that customers who allow their insurance to auto-renew will generally be paying much more for the same policy than a new customer. Many consumer champions such as Martin Lewis & MoneySavingExpert.com have long advocated searching around for the best deal each year, rather than letting your existing policy renew automatically. Millions of people have enjoyed success with this approach and hit the price comparison sites to secure the best deal. The knock on effect of this is that the insurers are starting to see this pattern emerge and therefore the cheaper deals for new customers are starting to dry up as the bargain basement quote new punters get is usually allowing the insurer to at best break even or sometimes they cover the first year at a loss, hoping you will renew and they will make their money out of you in the future. Due to people becoming more savvy and shopping around, the insurers are offering new customer deals less and less. Overall this then pushes up the average premium figure. It is still worth shopping around though!'
                },
                {
                    boldTitle: '4 - Modern vehicles are becoming more expensive to repair',
                    text: ', pushing up the cost of claims. Modern vehicles bristle with technology, such as sensors in bumpers, adaptive headlights, automatic parking systems etc. As more of us purchase newer vehicles via PCP plans, the more modern the “rolling stock” of Britain’s roads becomes and the more it costs to deal with repairs. Not a lot that can be done about this one. Insurers seldom make this point known though.'
                },
                {
                    boldTitle: '5 - We are in a “hard market” and there is “reduced capacity”',
                    text: '. Insurers are required to hold a lot more of their money as liquid cash and / or very low risk investments to protect themselves from large catastrophes or crashes in the market. So any drop in value in the physical buildings they own i.e. shopping centres and their other investments mean they have to increase their cash reserves at the expense of their other investments. The result of the contraction in their investments and increase cash reserves means Insurers are unable to accept so many customers. As the Insurers become more picky with the customers they accept, they charge a higher premium for the customers they will accept. We had been in a soft market due to good investment returns which results in new Insurers entering the market which force the premiums down (a price war). When a recession or catastrophe hits, you enter a Hard Market and capacity reduces, Insurers pull out of the markets and premiums go up as there are fewer insurers competing with each other for the same customers.'
                },
                {
                    text: 'It works in a circle though, as the premiums go up in the hard market, it attracts new Insurers into the marketplace and investments tend to pick up. This completes the circle by forcing premiums down and you return to a Soft Market until the next trigger event. Recently a Gibraltar based insurer, Enterprise Insurance, went bust. So again, fewer insurers competing for the business and the remaining insurers can charge more or be picky about who they are willing to insure.'
                },
                {
                    boldTitle: 'The not so REAL reasons your insurance has been going up:',
                },
                {
                    boldTitle: 'Fraud',
                    text: '– Let’s be straight, fraud does exist. Any fraud is unacceptable, and those caught and convicted face prison and deservedly so. Those caught/rumbled whilst not automatically facing prosecution (it is up to the insurer to decide whether to prosecute or not) will have their cards marked and won’t be able to get cheap insurance or will simply be declined.'
                },
                {
                    text: 'But the insurers over-play the fraud card and their figures are always a bit woolly and difficult to define in terms of what is pure “ fraud” being committed by say crash for cash gangs and what is “fraud” in terms of a policyholder perhaps failing to declare a conviction or a previous accident claim. They have also been known to include “suspected fraud” in their figures. So this supports a set of figures the insurers can claim show fraud is out of control and we are in the grip of a fraud pandemic, yet their year on year increases in profits seem to mirror the increase in people’s premiums and show that despite the alleged epidemic of fraudulent claims, the insurers are doing rather well.'
                },
                {
                    text: 'There is a criminal justice system there to deal with insurance fraudsters and innocent motorists and those injured on the roads should not expect to have their rights to claim removed due to the actions of a small and stupid minority.'
                },
                {
                    boldTitle: 'Whiplash',
                    text: '– Again, there are stupid people who exaggerate the extent of their injuries or even being injured at all and there are nuisance calls made by scumbag accident management companies and marketing companies who encourage people to “have a go”. But this is the dregs end of an accident management industry which could do with a clean up by the new incoming regulator, the Financial Conduct Authority as well as a review of laws regarding telemarketing.'
                },
                {
                    text: 'Let’s face it, any cold calling sucks, not just calls about personal injury, but also PPI, Charities, Boiler Suppliers and so on. It just needs to be banned and there needs to be an effective way of reporting the nuisance, such as pressing a certain number on your keypad when you receive the call, which then logs the call with your service provider or an independent monitor. This will stack up the evidence quickly and allow swift action to be taken against both the company AND the directors. This is all possible without the drastic steps the insurance industry have proposed to government and are about to go through the proposal stage.'
                },
                {
                    text: 'The insurance industry want to extinguish whiplash claims by making them barred by law if the injury is “minor” (yet to be defined) and also preventing recovery of any costs on claims under £5000.00 in value. This would essentially prevent people from claiming for minor whiplash, but also box lawyers out of the system, leaving injured parties to fight insurance companies alone in the small claims court, or have to forfeit a good chunk of any compensation to pay lawyers under no win no fee arrangements. So the insurer pays out less, the injured party receives less.'
                },
                {
                    text: 'Whilst this probably earns a round of applause from many people at this stage due to the constant drip-feeding of “we will lower your premiums if we get rid of these whiplash claims” in the media, independent ratings agency Fitch have just reported that their experts feel that insurers are unlikely to pass on any savings until at least 2018 if the reforms get passed through. Many organisations representing injured people feel the insurers are unlikely to ever pass on the savings and a recent article in the times accuses the insurers of failing to pass on savings from a previous round of reforms in 2013 resulting in substantial savings when lawyers fees were slashed by 60%. Since then, the number of claims being made has also declined. The substance behind the figures the insurance industry seek to rely on just don’t stack up.'
                },
                {
                    boldTitle: 'What we say at Free Motor Legal is be careful what you wish for',
                    text: '– Insurance companies are playing the public policy card i.e. what is good for them is good for Joe Public with promises of reduced insurance premiums, but the reality is that the insurance industry and their trade body, The Association of British Insurers, has for some time been misleading people why our premiums have increased (when have they revealed it is for reasons 1-5 above?). Can they really be trusted to deliver the savings they promise?'
                },
                {
                    text: 'The government has previously admitted nobody will police the insurance industry on whether the savings will be passed on or not. Past experience would urge extreme caution here.'
                },
                {
                    text: 'The promise from the insurance industry is an average saving of £50.00 if reforms are introduced (presumably all the reforms).'
                },
                {
                    text: 'Free Motor Legal offer a free alternative to paying for motor legal protection insurance. A separate add-on policy insurers often sell for an additional £30.00 per vehicle on cover. Yet Free Motor Legal can rival the services the insurers offer without charging their members any annual premium or membership fees. In fact, membership is free for life. With over 30 million motorists in the UK potentially paying up to £30 a year extra, that is £900 million in additional premiums the UK is paying for a service Free Motor Legal can provide for free, saving British motorists up to £900 million each year. Further details can be found at their website www.freemotorlegal.co.uk'
                }
            ]
        }
    ],
    "real-reasons": [
        {
            title: 'The REAL Reasons your motor insurance has been going up',
            subtitle: 'by fml | Sep 6, 2016 | Uncategorized',
            alignment: 'left',
            inline: true,
            description: [
                {
                    boldTitle: '1 -  Solvency ii -',
                    text: 'a requirement from January 2016 for insurers to hold more capital reserves (money in the bank to meet claims and liabilities). So they can’t invest as much in stocks & shares or buy shopping malls etc. Believe it or not, motor insurers typically don’t make their money from simple underwriting profits (collecting more in premiums than they pay out in claims) although many have moved into this territory in recent years with Combined Operating Ratios (COR’s) as low as 85% (meaning for every £1 they collect in premiums, 85p is paid out in claims). Because they don’t have as much spare cash they can pump into investments, this has caused them to look elsewhere for increased profits – yep you guessed it – their customers; Crank up the premiums, crank up the profits!'
                },
                {
                    boldTitle: '2 - People wising up to the loyalty sham/ auto renewal fiasco -',
                    text: 'The insurance industry really is a strange old bird. How many industries or businesses do you know that actually kick a loyal customer in the shins by charging them more than a new customer? Yes, it’s a strange one, but a fact that customers who allow their insurance to auto-renew will generally be paying much more for the same policy than a new customer. Many consumer champions such as Martin Lewis & MoneySavingExpert.com have long advocated searching around for the best deal each year, rather than letting your existing policy renew automatically. Millions of people have enjoyed success with this approach and hit the price comparison sites to secure the best deal. The knock on effect of this is that the insurers are starting to see this pattern emerge and therefore the cheaper deals for new customers are starting to dry up. Overall this then pushes up the average premium figure. It is still worth shopping around though!'
                },
                {
                    boldTitle: '3 -  Modern vehicles are becoming more expensive to repair,',
                    text: 'pushing up the cost of claims. Modern vehicles bristle with technology, such as sensors in bumpers, adaptive headlights, automatic parking systems etc. As more of us purchase newer vehicles via PCP plans, the more modern the “rolling stock” of Britain’s roads becomes and the more it costs to deal with repairs. Not a lot that can be done about this one.'
                },
                {
                    boldTitle: '4 - We are in a “hard market” -',
                    text: 'As insurers are required to hold a lot more of their money as liquid cash and / or very low risk investments to protect themselves from large catastrophes and / or crashes in the market. So the drop in value in the physical buildings they own i.e. shopping centres and their other investments mean they have to increase their cash reserves at the expense of their other investments.'
                },
                {
                    boldTitle: '5 - Lack of capacity in the market -',
                    text: 'We had been in a soft market due to good investment returns which results in new Insurers entering the market which force the premiums down (a price war). When a recession or catastrophe hits, you enter a Hard Market and capacity reduces, Insurers pull out of the markets and premiums go up as there are fewer insurers competing with each other for the same customers.'
                },
                {
                    text: 'It works in a circle though, as the premiums go up in the hard market, it attracts new Insurers into the marketplace and investments tend to pick up. This completes the circle by forcing premiums down and you return to a Soft Market until the next trigger event. Recently a Gibraltar based insurer, Enterprise Insurance, went bust. So again, fewer insurers competing for the business and the remaining insurers can charge more or be picky about who they are willing to insure.'
                },
                {
                    boldTitle: '6 - The insurance industry are playing everyone for fools by blaming price rises on fraud, whiplash claims and claims management companies.',
                    text: 'They have been jacking up the prices based largely on the reasons already outlined above, but they want to blame the problems on fraud and whiplash claims and claims management companies so that they can lobby government for changes to avoid paying any legal costs on injury claims with a value of less than £5k and even more dramatic than that, ban recovery of compensation for whiplash. If the insurers succeed with their dream of not paying legal costs on injury cases under £5k in value and abolishing whiplash, the savings they create will be biblical. They promise these savings will be passed onto consumers with reduced premiums, but they have already been caught out with this in a recent article in The Times, which challenged the previous savings promises had not been passed on.'
                },
                {
                    text: 'Be under no illusion, the insurance industry are as hell bent on profits and moulding the landscape in which they operate as the bankers. They are major players in our economy and have a sympathetic ear from government, who listen to the excuses as to why premiums have risen and take them as gospel. On many occasions the insurers are asked to be transparent with their figures and data on which they base their revelations and time and time again they are unable to come up with transparent and credible data.'
                },
                {
                    text: 'But let’s be honest, there is a problem with fraud, but nowhere near the extent to which the insurers bleat. There remains a system to deal with insurance fraud, it is called the criminal justice system. If fraud arises, investigate it and pass the evidence to the relevant authorities. Don’t deny all other UK citizens their rights just because of the actions of a minority and the never ending desire for greater profits.'
                },
                {
                    text: 'Whiplash does exist and it genuinely hurts and can affect people’s ability to work and can disrupt their lives for months and even years afterwards. We live in a time where government legislation now allows us to claim compensation due to a late train or flight, causing some minor inconvenience for mere hours, yet get injured in a road accident or at work and suffer limitations and restrictions for months or longer and the insurance industry want your rights to claim completely removed or for injured claimants to lose out by having to pay all their legal fees from their damages.'
                },
                {
                    text: 'Claims Management Companies (CMC), yes there are good and bad examples out there. CMCs do provide a helpful service and some are very well behaved. Others are an utter nuisance and use automated diallers to pester people over and over again or pressure them into making claims, promising them there is a pot of money just set aside for them etc. There needs to be a tightening of the regulations surrounding CMCs.'
                },
                {
                    text: 'The one key fact the insurance industry will routinely deny is that they are an integral part of the problem they are claiming about. It is a dysfunctional system that operates whereby insurers continue to cut each other’s throats by passing their customers onto law firms they have a relationship with or vehicle hire companies after non-fault accidents. Insurers are the biggest introducers of claimants into the system due to their own claims capture activities.'
                },
                {
                    text: 'As a result of this, the insurer of the at-fault party has a more expensive day out. This merry go round just repeats itself over & over again. Maybe the insurers should come clean about the £millions they earn in commissions from law firms, medical agencies & hire companies each year.'
                },
                {
                    text: 'The problem is they can blame the reasons for price rises on these issues and they will get sympathy from government and support from the public as they can relate to the nuisance call in the middle of East Enders or the press headlines about whiplash claims and insurance scams which have been drip fed via the media channels for years. Yet the problem is that if the insurers succeed in driving forwards reforms to drive a large sector of the personal injury industry out of business, the reasons for price hikes set out in points 1-5 will still be there and there will be another excuse for the reasons your premium is going up. So you will still be paying the same premiums, but you will have fewer rights if you get injured and a more expensive time making any claim you are entitled to. Meanwhile, expect another year of record profit announcements and dividend payments by insurance companies.'
                },
            ]
        }
    ]
}